const display_message = (message) => {
    /* eslint-disable */
    //   console.log(process.env,"env");
    if (["dev","local","test"].includes(process.env.VUE_APP_ENV)) {
        console.log(message,"messages");
      return message;
    }
    return "Please contact the administrator";
  };

  const display_drug_error_message = (drugName) => {
    const res = [
      {
        drugName: "Enoxaparin",
        drugMessage:
          "Dose recommendation for enoxaparin failed. Please make sure that gestational age, weight, serum creatinine, serum platelets (thrombocytes), and hematocrit results exist in Epic.",
      },
      {
        drugName: "Fosphenytoin",
        drugMessage:
          "Dose recommendation for fosphenytoin failed. Please make sure that weight, serum creatinine, and serum albumin results exist in Epic.",
      },
    ];
  
    for (const item of res) {
      if (item.drugName.toLowerCase() === drugName.toLowerCase()) {
        return item.drugMessage;
      }
    }
  
    return "Please contact administration if the error persists.";
  };
  
  export default {
    display_message,
    display_drug_error_message,
  };
